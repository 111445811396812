.gpt3__header {
    display: flex;
}

.gpt3__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;

}

.gpt3__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 63px;
    line-height: 75px;
    letter-spacing: -0.04em;

}

.gpt3__header-content p {
    font-family: var(--font-family);
    font-weight: normal;
    font-style: normal;
    font-size: 20px;
    line-height: 27px;
    color: #81AFDD;
 
    margin-top: 1.5rem;

}

.gpt3__header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;
}

.gpt3__header-content__input input {
    flex: 2;
    background: #052D56;
    min-height: 50px;
    border-radius: 5px 0px 0px 5px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #3D6184;
    background: var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;
    border-radius: 5px 0px 0px 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.gpt3__header-content__input button {
    background: #FF4820;
    flex: 0.6;
    border-radius: 5px 0px 0px 5px;
    transform: matrix(1, 0, 0, 1, 0, 0);
    font-family: var(--font-family);
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: #FFFFFF;
    min-height: 50px;
    cursor: pointer;
    outline: none;
    padding: 0 1rem;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.gpt3__header-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-top: 2rem;
}

.gpt3__header-content__people img {
    width: 181.79px;
    height: 38px;
}

.gpt3__header-content__people p{
    margin-left: 0 0 0 1rem;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    text-align: center;
    color: #FFFFFF;
}

.gpt3__header-image {
    flex: 1;
    display:flex;
    justify-content: center;
    align-items: center;
}

.gpt3__header-image img {
    width: 100%;
    height: 100%;   
}

@media screen and (max-width : 1050px) {
    .gpt3__header {
        flex-direction: column;
    }

    .gpt3__header-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width : 650px) {
    .gpt3__header h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .gpt3__header p {
        font-size: 16px;
        line-height: 24px;
    }

    .gpt3__header-content__people {
        flex-direction: column;
    }

    .gpt3__header-content__people p { 
        margin: 0;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
}


@media screen and (max-width : 490px) {
    .gpt3__header h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .gpt3__header p {
        font-size: 14px;
        line-height: 24px;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }

}